import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, graphql } from 'gatsby'

import Bio from 'components/bio'
import Layout from 'components/layout'
import SEO from 'components/seo'
import { rhythm } from 'utils/typography'
import Button from 'components/button'

const Blog = ({ data, location }) => {
  const { t } = useTranslation('blog')

  const blogTitle = data.site.siteMetadata.blogTitle
  const posts = data.allMdx.edges
  return (
    <Layout location={location} title={blogTitle}>
      <SEO title={t('title')} />
      <Bio />
      <div style={{ margin: '20px 0 40px' }}>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link
                  style={{ boxShadow: `none` }}
                  to={`/blog${node.fields.slug}`}
                >
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </div>
          )
        })}
      </div>
      <Link to="/">
        <Button marginTop="85px">{t('goHome')}</Button>
      </Link>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        blogTitle
      }
    }
    allMdx(
      filter: { fields: { sourceName: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
